import Vue from "vue";
import Router from "vue-router";
import {
  accueilPopvaletProData,
  popvaletGeneralAccueilData,
  popvaletParticulierAccueilData,
  popvaletGeneralVisionData,
  popvaletGeneralQualiteData,
  popvaletProServicesValetData,
  popvaletProServicesExpertiseData,
  popvaletProServicesCamionData,
  popvaletGeneralAssuranceData,
  popvaletGeneralNosUsesCasesData,
  popvaletProServicesReparationData,
  popvaletReserverTransportData,
  popvaletProServicesRestitutionData,
  popvaletGeneralCGUData,
  popvaletGeneralContactData,
  accueilMaifData,
  commentCaMarcheMaifData,
  popvaletMaifReserverTransportData,
  commentCaMarcheMatmutData,
  accueilMatmutData,
  privacyPolicyMaifData,
  privacyPolicyMatmutData,
  popvaletMatmutReserverTransportData,
  popvaletGeneralMaifTechnologieData,
  popvaletGeneralMaifReactiviteData,
  popvaletGeneralMaifContactData,
  popvaletGeneralMaifCGUData,
  popvaletGeneralMaifAssuranceData,
  popvaletProCommonData,
} from "./data";

const AccueilPopvaletPro = () => import(/* webpackChunkName: "AccueilPopvaletPro" */ './components/AccueilPopvaletPro');
const PopvaletGeneralAccueil = () => import(/* webpackChunkName: "PopvaletGeneralAccueil" */ './components/PopvaletGeneralAccueil');
const PopvaletParticulierAccueil = () => import(/* webpackChunkName: "PopvaletParticulierAccueil" */ './components/PopvaletParticulierAccueil');
const PopvaletGeneralVision = () => import(/* webpackChunkName: "PopvaletGeneralVision" */ './components/PopvaletGeneralVision');
const PopvaletGeneralQualite = () => import(/* webpackChunkName: "PopvaletGeneralQualite" */ './components/PopvaletGeneralQualite');
const PopvaletProServicesValet = () => import(/* webpackChunkName: "PopvaletProServicesValet" */ './components/PopvaletProServicesValet');
const PopvaletProServicesExpertise = () => import(/* webpackChunkName: "PopvaletProServicesExpertise" */ './components/PopvaletProServicesExpertise');
const PopvaletProServicesCamion = () => import(/* webpackChunkName: "PopvaletProServicesCamion" */ './components/PopvaletProServicesCamion');
const AssurancePopValet = () => import(/* webpackChunkName: "AssurancePopValet" */ '@/components/AssurancePopValet');
const NosUsesCases = () => import(/* webpackChunkName: "NosUsesCases" */ '@/components/NosUsesCases');
const ReactiviteForte = () => import(/* webpackChunkName: "ReactiviteForte" */ '@/components/ReactiviteForte');
const Technologie = () => import(/* webpackChunkName: "Technologie" */ '@/components/Technologie');
const PopvaletProServicesReparation = () => import(/* webpackChunkName: "PopvaletProServicesReparation" */ '@/components/PopvaletProServicesReparation');
const PopvaletReserverTransport = () => import(/* webpackChunkName: "PopvaletReserverTransport" */ '@/components/PopvaletReserverTransport');
const PopvaletProServicesRestitution = () => import(/* webpackChunkName: "PopvaletProServicesRestitution" */ '@/components/PopvaletProServicesRestitution');
const PopValetProServicesValetLivraison = () => import(/* webpackChunkName: "PopValetProServicesValetLivraison" */ '@/components/PopValetProServicesValetLivraison');
const PopValetProServicesValetConvoyage = () => import(/* webpackChunkName: "PopValetProServicesValetConvoyage" */ '@/components/PopValetProServicesValetConvoyage');
const PopValetProServicesValetJockeyage = () => import(/* webpackChunkName: "PopValetProServicesValetJockeyage" */ '@/components/PopValetProServicesValetJockeyage');
const PopvaletProServicesCamionPorte8 = () => import(/* webpackChunkName: "PopvaletProServicesCamionPorte8" */ '@/components/PopvaletProServicesCamionPorte8');
const PopvaletProServicesCamionDepanneuse = () => import(/* webpackChunkName: "PopvaletProServicesCamionDepanneuse" */ '@/components/PopvaletProServicesCamionDepanneuse');
const PopvaletProServicesReparationInfo = () => import(/* webpackChunkName: "PopvaletProServicesReparationInfo" */ '@/components/PopvaletProServicesReparationInfo');
const PopvaletProServicesExpertiseInfo = () => import(/* webpackChunkName: "PopvaletProServicesExpertiseInfo" */ '@/components/PopvaletProServicesExpertiseInfo');
const PopvaletProServicesRestitutionInfo = () => import(/* webpackChunkName: "PopvaletProServicesRestitutionInfo" */ '@/components/PopvaletProServicesRestitutionInfo');
const PopvaletGeneralVisionInfo = () => import(/* webpackChunkName: "PopvaletGeneralVisionInfo" */ '@/components/PopvaletGeneralVisionInfo');
const PopvaletGeneralQualiteInfo = () => import(/* webpackChunkName: "PopvaletGeneralQualiteInfo" */ '@/components/PopvaletGeneralQualiteInfo');
const ReactiviteForteInfo = () => import(/* webpackChunkName: "ReactiviteForteInfo" */ '@/components/ReactiviteForteInfo');
const TechnologieInfo = () => import(/* webpackChunkName: "TechnologieInfo" */ '@/components/TechnologieInfo');
const GeneralCGU = () => import(/* webpackChunkName: "GeneralCGU" */ '@/components/GeneralCGU');
const GeneralContact = () => import(/* webpackChunkName: "GeneralContact" */ '@/components/GeneralContact');
const AccueilMaifPopvalet = () => import(/* webpackChunkName: "AccueilMaifPopvalet" */ '@/components/maif/AccueilMaifPopvalet.vue');
const CommentCaMarcheMaifPopvalet = () => import(/* webpackChunkName: "CommentCaMarcheMaifPopvalet" */ '@/components/maif/CommentCaMarcheMaifPopvalet.vue');
const PrivacyPolicyMaifPopValet = () => import(/* webpackChunkName: "PrivacyPolicyMaifPopValet" */ '@/components/maif/PrivacyPolicyMaifPopValet.vue');
const CommentCaMarcheMatmutPopvalet = () => import(/* webpackChunkName: "CommentCaMarcheMatmutPopvalet" */ '@/components/matmut/CommentCaMarcheMatmutPopvalet.vue');
const PopValetProTransportVoiture = () => import(/* webpackChunkName: "PopValetProTransportVoiture" */ '@/components/PopValetProTransportVoiture.vue');
const PopValetProTransportVehiculePlateau = () => import(/* webpackChunkName: "PopValetProTransportVehiculePlateau" */ '@/components/PopValetProTransportVehiculePlateau.vue');
const PopValetProTransportVoitureRemorque = () => import(/* webpackChunkName: "PopValetProTransportVoitureRemorque" */ '@/components/PopValetProTransportVoitureRemorque.vue');
const PopValetProTransportVoitureLocation = () => import(/* webpackChunkName: "PopValetProTransportVoitureLocation" */ '@/components/PopValetProTransportVoitureLocation.vue');
const PopValetProTransportVoitureLuxe = () => import(/* webpackChunkName: "PopValetProTransportVoitureLuxe" */ '@/components/PopValetProTransportVoitureLuxe.vue');
const PopValetProTransportVoitureCollection = () => import(/* webpackChunkName: "PopValetProTransportVoitureCollection" */ '@/components/PopValetProTransportVoitureCollection.vue');
const PopValetProTransportVoitureEurope = () => import(/* webpackChunkName: "PopValetProTransportVoitureEurope" */ '@/components/PopValetProTransportVoitureEurope.vue');
const PopValetProTransportVoitureFrance = () => import(/* webpackChunkName: "PopValetProTransportVoitureFrance" */ '@/components/PopValetProTransportVoitureFrance.vue');
const PopValetProTransportVoitureAllemagneFrance = () => import(/* webpackChunkName: "PopValetProTransportVoitureAllemagneFrance" */ '@/components/PopValetProTransportVoitureAllemagneFrance.vue');
const PopValetProTransportVoitureItalieFrance = () => import(/* webpackChunkName: "PopValetProTransportVoitureItalieFrance" */ '@/components/PopValetProTransportVoitureItalieFrance.vue');
const PopValetProTransportVoitureEspagneFrance = () => import(/* webpackChunkName: "PopValetProTransportVoitureEspagneFrance" */ '@/components/PopValetProTransportVoitureEspagneFrance.vue');
const PopValetProTransportVoitureSuedeFrance = () => import(/* webpackChunkName: "PopValetProTransportVoitureSuedeFrance" */ '@/components/PopValetProTransportVoitureSuedeFrance.vue');
const PopValetProTransportVoitureBelgiqueFrance = () => import(/* webpackChunkName: "PopValetProTransportVoitureBelgiqueFrance" */ '@/components/PopValetProTransportVoitureBelgiqueFrance.vue');
const PopValetProTransportVoitureParis = () => import(/* webpackChunkName: "PopValetProTransportVoitureParis" */ '@/components/PopValetProTransportVoitureParis.vue');
const PopValetProTransportVoitureLyon = () => import(/* webpackChunkName: "PopValetProTransportVoitureLyon" */ '@/components/PopValetProTransportVoitureLyon.vue');
const PopValetProTransportVoitureMarseille = () => import(/* webpackChunkName: "PopValetProTransportVoitureMarseille" */ '@/components/PopValetProTransportVoitureMarseille.vue');
const PopValetProTransportVoitureBordeaux = () => import(/* webpackChunkName: "PopValetProTransportVoitureBordeaux" */ '@/components/PopValetProTransportVoitureBordeaux.vue');
const PopValetProTransportVoitureLille = () => import(/* webpackChunkName: "PopValetProTransportVoitureLille" */ '@/components/PopValetProTransportVoitureLille.vue');
const PopValetProTransportVoitureNantes = () => import(/* webpackChunkName: "PopValetProTransportVoitureNantes" */ '@/components/PopValetProTransportVoitureNantes.vue');

Vue.use(Router);

let routes

if (window.location.hostname.startsWith('maif')) {
  routes = [
    {
      path: "*",
      component: AccueilMaifPopvalet,
      props: { ...accueilMaifData },
    },
    {
      path: "/comment-ca-marche",
      component: CommentCaMarcheMaifPopvalet,
      props: { ...commentCaMarcheMaifData },
    },
    {
      path: "/reserver-votre-transport",
      component: PopvaletReserverTransport,
      props: { ...popvaletMaifReserverTransportData },
    },
    {
      path: "/popvalet-general-vision",
      redirect: '/vision',
    },
    {
      path: "/popvalet-general-vision-info",
      redirect: '/vision-logistique-automobile',
    },
    {
      path: "/vision",
      component: PopvaletGeneralVision,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/vision-logistique-automobile",
      component: PopvaletGeneralVisionInfo,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/popvalet-general-qualite",
      redirect: '/qualite',
    },
    {
      path: "/popvalet-general-qualite-info",
      redirect: '/qualite-transports',
    },
    {
      path: "/qualite",
      component: PopvaletGeneralQualite,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/qualite-transports",
      component: PopvaletGeneralQualiteInfo,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/popvalet-general-reactivite",
      redirect: '/reactivite',
    },
    {
      path: "/popvalet-general-reactivite-info",
      redirect: '/reactivite-transport',
    },
    {
      path: "/reactivite",
      component: ReactiviteForte,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/reactivite-transport",
      component: ReactiviteForteInfo,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/popvalet-general-technologie",
      redirect: '/technologie',
    },
    {
      path: "/popvalet-general-technologie-info",
      redirect: 'technologie-transports-vehicules',
    },
    {
      path: "/technologie",
      component: Technologie,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/technologie-transports-vehicules",
      component: TechnologieInfo,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/popvalet-general-assurance",
      component: AssurancePopValet,
      props: { ...popvaletGeneralMaifAssuranceData },
    },
    {
      path: "/popvalet-general-cgu",
      component: GeneralCGU,
      props: { ...popvaletGeneralMaifCGUData },
    },
    {
      path: "/popvalet-general-contact",
      component: GeneralContact,
      props: { ...popvaletGeneralMaifContactData },
    },
    {
      path: "/privacy-policy/",
      component: PrivacyPolicyMaifPopValet,
      props: { ...privacyPolicyMaifData },
    }
  ]
} else if (window.location.hostname.startsWith('matmut')) {
  routes = [
    {
      path: "*",
      component: AccueilMaifPopvalet,
      props: { ...accueilMatmutData },
    },
    {
      path: "/comment-ca-marche",
      component: CommentCaMarcheMatmutPopvalet,
      props: { ...commentCaMarcheMatmutData },
    },
    {
      path: "/reserver-votre-transport",
      component: PopvaletReserverTransport,
      props: { ...popvaletMatmutReserverTransportData },
    },
    {
      path: "/popvalet-general-vision",
      redirect: '/vision',
    },
    {
      path: "/popvalet-general-vision-info",
      redirect: '/vision-logistique-automobile',
    },
    {
      path: "/vision",
      component: PopvaletGeneralVision,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/vision-logistique-automobile",
      component: PopvaletGeneralVisionInfo,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/popvalet-general-qualite",
      redirect: '/qualite',
    },
    {
      path: "/popvalet-general-qualite-info",
      redirect: '/qualite-transports',
    },
    {
      path: "/qualite",
      component: PopvaletGeneralQualite,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/qualite-transports",
      component: PopvaletGeneralQualiteInfo,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/popvalet-general-reactivite",
      redirect: '/reactivite',
    },
    {
      path: "/popvalet-general-reactivite-info",
      redirect: '/reactivite-transport',
    },
    {
      path: "/reactivite",
      component: ReactiviteForte,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/reactivite-transport",
      component: ReactiviteForteInfo,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/popvalet-general-technologie",
      redirect: '/technologie',
    },
    {
      path: "/popvalet-general-technologie-info",
      redirect: 'technologie-transports-vehicules',
    },
    {
      path: "/technologie",
      component: Technologie,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/technologie-transports-vehicules",
      component: TechnologieInfo,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/popvalet-general-assurance",
      component: AssurancePopValet,
      props: { ...popvaletGeneralMaifAssuranceData },
    },
    {
      path: "/popvalet-general-cgu",
      component: GeneralCGU,
      props: { ...popvaletGeneralMaifCGUData },
    },
    {
      path: "/popvalet-general-contact",
      component: GeneralContact,
      props: { ...popvaletGeneralMaifContactData },
    },
    {
      path: "/privacy-policy/",
      component: PrivacyPolicyMaifPopValet,
      props: { ...privacyPolicyMatmutData },
    }
  ]
} else {
  routes = [
    {
      path: "*",
      component: PopvaletGeneralAccueil,
      props: { ...popvaletGeneralAccueilData },
    },
    {
      path: "/popvalet-general-assurance",
      component: AssurancePopValet,
      props: { ...popvaletGeneralAssuranceData },
    },
    {
      path: "/popvalet-general-vision",
      redirect: '/vision',
    },
    {
      path: "/popvalet-general-vision-info",
      redirect: '/vision-logistique-automobile',
    },
    {
      path: "/vision",
      component: PopvaletGeneralVision,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/vision-logistique-automobile",
      component: PopvaletGeneralVisionInfo,
      props: { ...popvaletGeneralVisionData },
    },
    {
      path: "/popvalet-general-qualite",
      redirect: '/qualite',
    },
    {
      path: "/popvalet-general-qualite-info",
      redirect: '/qualite-transports',
    },
    {
      path: "/qualite",
      component: PopvaletGeneralQualite,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/qualite-transports",
      component: PopvaletGeneralQualiteInfo,
      props: { ...popvaletGeneralQualiteData },
    },
    {
      path: "/popvalet-general-nos-use-cases",
      redirect: '/nos-use-cases',
    },
    {
      path: "/nos-use-cases",
      component: NosUsesCases,
      props: { ...popvaletGeneralNosUsesCasesData },
    },
    {
      path: "/popvalet-general-reactivite",
      redirect: '/reactivite',
    },
    {
      path: "/popvalet-general-reactivite-info",
      redirect: '/reactivite-transport',
    },
    {
      path: "/reactivite",
      component: ReactiviteForte,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/reactivite-transport",
      component: ReactiviteForteInfo,
      props: { ...popvaletGeneralMaifReactiviteData },
    },
    {
      path: "/popvalet-general-technologie",
      redirect: '/technologie',
    },
    {
      path: "/popvalet-general-technologie-info",
      redirect: 'technologie-transports-vehicules',
    },
    {
      path: "/technologie",
      component: Technologie,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/technologie-transports-vehicules",
      component: TechnologieInfo,
      props: { ...popvaletGeneralMaifTechnologieData },
    },
    {
      path: "/popvalet-general-cgu",
      component: GeneralCGU,
      props: { ...popvaletGeneralCGUData },
    },
    {
      path: "/popvalet-general-contact",
      component: GeneralContact,
      props: { ...popvaletGeneralContactData },
    },
    {
      path: "/professionnels",
      component: AccueilPopvaletPro,
      props: { ...accueilPopvaletProData },
    },
    {
      path: "/popvalet-pro-services-valet",
      redirect: '/transport-avec-chauffeur',
    },
    {
      path: "/popvalet-pro-services-valet-livraison",
      redirect: '/livraison-voiture',
    },
    {
      path: "/popvalet-pro-services-valet-convoyage",
      redirect: '/convoyage-voiture',
    },
    {
      path: "/popvalet-pro-services-valet-jockeyage",
      redirect: '/jockeyage-voiture',
    },
    {
      path: "/transport-avec-chauffeur",
      component: PopvaletProServicesValet,
      props: { ...popvaletProServicesValetData },
    },
    {
      path: "/livraison-voiture",
      component: PopValetProServicesValetLivraison,
      props: { ...popvaletProServicesValetData },
    },
    {
      path: "/convoyage-voiture",
      component: PopValetProServicesValetConvoyage,
      props: { ...popvaletProServicesValetData },
    },
    {
      path: "/jockeyage-voiture",
      component: PopValetProServicesValetJockeyage,
      props: { ...popvaletProServicesValetData },
    },
    {
      path: "/popvalet-pro-services-expertise",
      redirect: '/expertise',
    },
    {
      path: "/popvalet-pro-services-expertise-info",
      redirect: '/expertise-vehicules-professionnels',
    },
    {
      path: "/expertise",
      component: PopvaletProServicesExpertise,
      props: { ...popvaletProServicesExpertiseData },
    },
    {
      path: "/expertise-vehicules-professionnels",
      component: PopvaletProServicesExpertiseInfo,
      props: { ...popvaletProServicesExpertiseData },
    },
    {
      path: "/popvalet-pro-services-camion",
      redirect: '/transport-voiture-camion',
    },
    {
      path: "/popvalet-pro-services-camion-porte8",
      redirect: '/transport-vehicule-porte8',
    },
    {
      path: "/popvalet-pro-services-camion-depanneuse",
      redirect: '/transport-vehicule-depanneuse',
    },
    {
      path: "/transport-voiture-camion",
      component: PopvaletProServicesCamion,
      props: { ...popvaletProServicesCamionData },
    },
    {
      path: "/transport-vehicule-porte8",
      component: PopvaletProServicesCamionPorte8,
      props: { ...popvaletProServicesCamionData },
    },
    {
      path: "/transport-vehicule-depanneuse",
      component: PopvaletProServicesCamionDepanneuse,
      props: { ...popvaletProServicesCamionData },
    },
    {
      path: "/popvalet-pro-services-reparation",
      redirect: '/reparation',
    },
    {
      path: "/popvalet-pro-services-reparation-info",
      redirect: '/reparation-vehicules-professionnels',
    },
    {
      path: "/reparation",
      component: PopvaletProServicesReparation,
      props: { ...popvaletProServicesReparationData },
    },
    {
      path: "/reparation-vehicules-professionnels",
      component: PopvaletProServicesReparationInfo,
      props: { ...popvaletProServicesReparationData },
    },
    {
      path: "/popvalet-pro-services-restitution",
      redirect: '/restitution',
    },
    {
      path: "/popvalet-pro-services-restitution-info",
      redirect: '/restitution-vehicules-professionnels',
    },
    {
      path: "/restitution",
      component: PopvaletProServicesRestitution,
      props: { ...popvaletProServicesRestitutionData },
    },
    {
      path: "/restitution-vehicules-professionnels",
      component: PopvaletProServicesRestitutionInfo,
      props: { ...popvaletProServicesRestitutionData },
    },
    {
      path: "/particuliers",
      component: PopvaletParticulierAccueil,
      props: { ...popvaletParticulierAccueilData },
    },
    {
      path: "/reserver-votre-transport",
      component: PopvaletReserverTransport,
      props: { ...popvaletReserverTransportData },
    },
    {
      path: "/transport-voiture",
      component: PopValetProTransportVoiture,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-vehicule-plateau",
      component: PopValetProTransportVehiculePlateau,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-remorque",
      component: PopValetProTransportVoitureRemorque,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-location",
      component: PopValetProTransportVoitureLocation,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-luxe",
      component: PopValetProTransportVoitureLuxe,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-collection",
      component: PopValetProTransportVoitureCollection,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-europe",
      component: PopValetProTransportVoitureEurope,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-france",
      component: PopValetProTransportVoitureFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-allemagne-france",
      component: PopValetProTransportVoitureAllemagneFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-italie-france",
      component: PopValetProTransportVoitureItalieFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-espagne-france",
      component: PopValetProTransportVoitureEspagneFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-suede-france",
      component: PopValetProTransportVoitureSuedeFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-belgique-france",
      component: PopValetProTransportVoitureBelgiqueFrance,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-paris",
      component: PopValetProTransportVoitureParis,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-lyon",
      component: PopValetProTransportVoitureLyon,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-marseille",
      component: PopValetProTransportVoitureMarseille,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-bordeaux",
      component: PopValetProTransportVoitureBordeaux,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-lille",
      component: PopValetProTransportVoitureLille,
      props: { ...popvaletProCommonData },
    },
    {
      path: "/transport-voiture-nantes",
      component: PopValetProTransportVoitureNantes,
      props: { ...popvaletProCommonData },
    },
  ]
}
export default new Router({
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    return {x: 0, y: 0};
  },
  routes
});